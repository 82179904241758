<template>
  <div class="container">
    <div class="detail">
      <div class="msg">
        <p class="name">{{detail.productName}}</p>
        <p class="price"><span>￥</span>{{detail.orderPrice}}</p>
        <p class="status">{{detail.orderStatusDisplay}}<em v-if="detail.orderStatus == 1">·<span>{{detail.mm}}</span>分<span>{{detail.ss}}</span>秒后自动取消</em></p>
      </div>
      <div class="list">
        <div class="item">
          <p>订单编号</p>
          <p>{{detail.orderNo}}</p>
        </div>
        <div class="item">
          <p>订单金额</p>
          <p>{{detail.orderPrice}}</p>
        </div>
        <div class="item">
          <p>下单时间</p>
          <p>{{detail.makeOrderTime}}</p>
        </div>
      </div>
       <div class="list" v-if="detail.orderStatus != 1">
        <div class="item">
          <p>支付方式</p>
          <p>{{detail.paymentMethodDisplay}}</p>
        </div>
        <div class="item" v-if="detail.paidTime != null && detail.paidTime != undefined">
          <p>支付时间</p>
          <p>{{detail.paidTime}}</p>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <p>产品名称</p>
          <p>{{detail.productName}}</p>
        </div>
        <div class="item">
          <p>产品所属</p>
          <p>{{detail.productShip}}</p>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <p>购买人姓名</p>
          <p>{{detail.makeOrderUserName}}</p>
        </div>
        <div class="item">
          <p>购买人手机号</p>
          <p>{{detail.makeOrderUserMobile}}</p>
        </div>
      </div>
    </div>
    <p class="btn" v-if="detail.orderStatus == 1" @click="payOrderWxPub">立即支付</p>
  </div>
</template>
<script>
import { getOrderDetail,cancelOrder,payOrderWxPub } from "../utils/api";
export default {
  data() {
    return {
      detail:null
    };
  },

  mounted() {
    document.title = "订单详情";
    this.getOrderDetail();
  },
  methods: {
    //订单详情
    getOrderDetail(){
      let params = {
        orderNo:this.$route.query.orderNo,
        openId:sessionStorage.getItem('openid'),
      }
      getOrderDetail(params).then(res => {
        console.log(res)
        this.detail = res.result;
        if (this.detail.orderStatus == 1) {
          this.$set(this.detail, "ss", this.getTimeList(15));
        }
      })
    },
    //去支付
    payOrderWxPub(){
      let params = {
        openId:sessionStorage.getItem('openid'),
        channelId: "wx20508d66c240b36a",
        orderNo: this.detail.orderNo,
      };
      payOrderWxPub(params).then((res) => {
        let xmIssueParams = {
          feeAmount: (this.detail.orderPrice)*100,
          orderNo: this.detail.orderNo,
          proposalNumber: this.detail.proposalNumber,
        }
        localStorage.setItem("xmIssueParams", JSON.stringify(xmIssueParams));
        window.location.href = res.result;
      });
    },
     //取消订单
    cancelOrder() {
      let params = {
        orderNo: this.detail.orderNo,
        proposalNumber: this.detail.proposalNumber,
      };
      cancelOrder(params).then((res) => {
        this.getOrderDetail();
        this.$toast.success('取消成功')
      });
    },
    getTimeList(time) {
      let _this = this;
      var interval = setInterval(function () {
        let a = _this.detail.makeOrderTime;
        const b = a.replace(/-/g, "/");
        let ti = new Date(b).getTime();
        var start = new Date().getTime();
        var end = new Date(ti + 1 * 60 * 60 * 1000).getTime();
        var rightTime = end - start;
        if (rightTime > 0) {
          var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
          var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
          var mm = Math.floor((rightTime / 1000 / 60) % 60);
          var ss = Math.floor((rightTime / 1000) % 60);
          dd = dd > 9 ? dd : "0" + dd;
          hh = hh > 9 ? hh : "0" + hh;
          mm = mm > 9 ? mm : "0" + mm;
          ss = ss > 9 ? ss : "0" + ss;
          _this.detail["dd"] = dd;
          _this.detail["hh"] = hh;
          _this.detail["mm"] = mm;
          _this.detail["ss"] = ss;
        } else {
          _this.detail["dd"] = 0;
          _this.detail["hh"] = 0;
          _this.detail["mm"] = 0;
          _this.detail["ss"] = 0;
          clearInterval(interval);
          _this.getOrderDetail();
        }
      }, 1000);
    },
  },
};
</script>
 
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}
.detail {
  width: 340px;
  height: 567px;
  background: url("../assets/detail-bg.png") no-repeat;
  background-size: 100%;
  margin-top: 15px;
  .msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px dashed #EDEDED;
    margin: 0 19px;
    padding-bottom: 15px;
    .name{
      font-size: 17px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-top: 30px;
    }
    .price{
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
      margin-top: 10px;
      span{
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 14px;
        padding-right: 6px;
      }
    }
    .status{
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top: 8px;
      span{
        color: $primary-color;
      }
    }
  }
  .list:last-child{
    border: none;
  }
  .list{
    margin: 17px 25px;
    border-bottom: 2px dashed #EDEDED;
    .item{
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      p{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
      p:first-child{
        width: 90px;
      }
      p:last-child{
        width: 100%;
        flex: 1;
      }
    }
  }
}
.btn {
  width: 250px;
  height: 45px;
  background: $primary-color;
  border-radius: 23px;
  margin-top: 31px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>